.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bingo-ball {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid black;
  box-shadow: 1px 1px 10px 1px #000000;

}

.B-ball {
  background-color: blue;
}
.I-ball {
  background-color: red;
}
.N-ball {
  background-color: white;
  color: black;
}
.G-ball {
  background-color: green;
}
.O-ball {
  background-color: orange;
}

.bingo-ball:hover {
  transform: scale(1.1);
}

.bingo-ball-face {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  top:-10px;
  left: 5px;
  border: 2px solid black;
  position: relative;
}

@media screen and (max-width: 768px){
  .bingo-ball {
    width: 50px;
    height: 50px;
    font-size: 12px;
  }
  .bingo-ball-face {
    width: 30px;
    height: 30px;
    font-size: 15px;
    top: -5px;
    left: 2px;
  }
}