// Default variable overrides

// $primary: #ff0000;
$body-bg: #C6E0FF;
$secondary: #018E42;
$danger: #AD343E;
$bs-btn-color: #fff;
$primary: #2978A0;
$--bs-modal-bg: #fff;

* {
  font-family: 'Josefin Sans', sans-serif;
}

.modal-content {
  background-color: #fff !important;
  color: #000;
}

#tool-bar {
  color: #000;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: .5rem 1rem;
  width: 100%;
  box-shadow: 0px 0px 10px 0px #8e8e8e;
}

#app-container {
  box-shadow: 0px 0px 10px 0px #8e8e8e;
}

.pattern-position {
  height: 20%;
  width: 20%;
  border: 1px solid;
  width: 19%;
}
#alert-container {
  position: fixed;
  bottom: 5rem;
  z-index: 2000;
  width: 100%;
  padding: 0 1.5rem;
}

#alert {
  padding: .5rem 1rem;
  color: #000;
  border-color: $danger;
  border: 2px solid;
  margin: 0 auto;
  border-radius: 1rem;
}
.pattern-position.active {
  background-color: $primary
}

.called-number-board .board-number {
  width: 32px;
  height: 32px;
}

//$body-color: #111;
// Required
@import "../../node_modules/bootstrap/scss/bootstrap";